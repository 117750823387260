'use client'
import React from 'react'
import Link from "next/link"
import Image from "next/image"
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';


export default function Navbar() {
  const[menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const menuVariants = {
    open: { 
        opacity: 1, 
        height: 'auto', 
        transition: { duration: 0.3, ease: 'easeInOut' } 
    },
    closed: { 
        opacity: 0, 
        height: 0, 
        transition: { duration: 0.3, ease: 'easeInOut' } 
    },
  };

  return (
    <nav className='navbar'>
        <div className='flex aling-center'>
            <Link href="/" className='logo'>
                    <Image src="/images/logo.png" alt="Cleanit Logo" width={100} height={50} />
            </Link>
        </div>
        <div className={`navbar-toggle ${menuOpen ? 'is-active' : ''}`} id="mobile-menu" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <ul className={`navbar-links computer-links ${menuOpen ? 'remove-links' : ''}`} >
              <li>
                <Link href="#gallery" title="Sneakers Helsinki" className='navbar-link' onClick={toggleMenu}>Galleria</Link>
              </li>
              <li>
                <Link href="#process" title="Miten kengät pestään" className='navbar-link' onClick={toggleMenu}>Miten pesu toimii?</Link>
              </li>
              <li>
                <Link href="#service" title="kenkien pesu" className='navbar-link' onClick={toggleMenu}>Palvelut</Link>
              </li>
              <li>
                <Link href="#contact" title="näin teet kengistäsi puhtaat" className='button' onClick={toggleMenu}>Ota Yhteyttä</Link>
              </li>
        </ul>
        <AnimatePresence>
          {menuOpen && (
            <motion.ul
              className={`navbar-links`}
              initial="closed"
              animate="open"
              exit="closed"
              variants={menuVariants}
            >
              <li>
                <Link href="#gallery" title="Sneakers Helsinki" className='navbar-link' onClick={toggleMenu}>Galleria</Link>
              </li>
              <li>
                <Link href="#process" title="Miten kengät pestään" className='navbar-link' onClick={toggleMenu}>Miten pesu toimii?</Link>
              </li>
              <li>
                <Link href="#service" title="Kenkien pesu" className='navbar-link' onClick={toggleMenu}>Palvelut</Link>
              </li>
              <li>
                <Link href="#contact" title="Tee kengistäsi puhtaat" className='button' onClick={toggleMenu}>Ota Yhteyttä</Link>
              </li>
            </motion.ul>
          )}
        </AnimatePresence>
        {/* <ul className={`navbar-links ${menuOpen ? 'menu-open' : ''}`}> */}
        
      {/* </ul> */}
    </nav>
  )
}
