'use client';

import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { ParallaxProvider } from 'react-scroll-parallax';

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <ChakraProvider theme={theme}>
      <ParallaxProvider>
        {children}
      </ParallaxProvider>
    </ChakraProvider>
  );
}